import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import NoData from '../assets/undraw_no_data_re_kwbl.svg';
import Stack from '@mui/material/Stack';
import NotSupported from '../assets/NotSupported.svg';
import _ from 'lodash';

const NoDataComponent = ({hidden = true, periodType, reportType, screen, testId}) => {
  if (hidden) {
    return null
  }
  if (!_.isNil(periodType) && periodType !== 'month' && !_.includes(['BALANCE_SHEET', 'INCOME_STATEMENT_GAAP', 'INCOME_STATEMENT_NON_GAAP'], reportType)) {
    return (
      <Stack
        direction={'column'}
        datatestid={testId}
        height="100%"
        alignSelf={'center'}
        flex={1}
        alignItems={'center'}
        justifyContent={'center'}
        sx={styles.notSupportedBox}>
        <img src={NotSupported} alt={''} />
        <Typography marginTop={'2.25rem'} textAlign={'center'} fontSize={'1.25rem'} fontWeight={'700'} lineHeight={'1.75rem'}>Quarterly and annual periods are not yet supported for this report</Typography>
        <Typography marginTop={'0.5rem'} textAlign={'center'} fontSize={'1rem'} fontWeight={'500'} lineHeight={'1.5rem'}>Access to these periods is coming soon.</Typography>
        <Typography marginTop={'2.5rem'} textAlign={'center'} fontSize={'0.875rem'} fontWeight={'400'} lineHeight={'1.25rem'}>Change the period to a specific month(s) to view results for this report.</Typography>
      </Stack>
    )
  }
  return (
    <Box
      datatestid={testId}
      display={'flex'}
      flex={1}
      alignSelf={'center'}
      sx={styles.noDataBox}>
      <img src={NoData} alt={''} height={150} />
      <Typography marginTop={'1em'}>No {screen} data was found. Please contact us if you think this was in error.</Typography>
    </Box>
  );
}

NoDataComponent.propTypes = {
  hidden: PropTypes.bool.isRequired,
  screen: PropTypes.string.isRequired,
  periodType: PropTypes.string,
  reportType: PropTypes.string,
  testId: PropTypes.string,
}

const styles = {
  noDataBox: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    typography: 'body1',
    justifyContent: 'center',
    alignItems: 'center',
    height: 300,
  },
}

export default NoDataComponent;
